import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      Powered by
      <a
        href="https://github.com/gpasxalis"
        target="_blank"
        rel="noopener noreferrer"
      >
        Dalution
      </a>
      
    </div>
  );
}
