import React from "react";
import FeaturedItems from "./FeaturedItems";

export default function Featured() {
	return (
		
		<FeaturedItems title="Our Menu" />
    	
  	);
}
