import React from "react";
import "./OnlineMenuStyle.css";

export default function OnlineMenu() {
	return (
		<div className="online-menu--section">
			<h1 className="online-menu--title">Under Construction</h1>
    	</div>
  	);
}
